<template>
  <div>
    <PageHeader fileName="header-8" withTablet withMobile>
      <h3>{{ $lang("title") }}</h3>
    </PageHeader>

    <section class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 d-flex flex-column">
            <h2 class="mb-4" v-html="$lang('section1.header')" />

            <ul class="mt-auto mb-4 h4">
              <li class="mb-3">
                <i class="fa fa-arrow-right text-tertiary mr-3"></i>
                <a class="text-dark a-decorated" href="#spanish">
                  {{ $lang("section1.spanish") }}
                </a>
              </li>
              <li class="mb-3">
                <i class="fa fa-arrow-right text-tertiary mr-3"></i>
                <a class="text-dark a-decorated" href="#english">
                  {{ $lang("section1.english") }}
                </a>
              </li>
              <li>
                <i class="fa fa-arrow-right text-tertiary mr-3"></i>
                <a class="text-dark a-decorated" href="#dele_siele">
                  {{ $lang("section1.dele_siele") }}
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-6">
            <h6 class="fw-400 lh-3 mb-4 mt-3">
              {{ $lang("section1.paragraph1") }}
            </h6>

            <p v-html="$lang('section1.paragraph2')" />

            <p>
              {{ $lang("section1.paragraph3") }}
            </p>

            <p>
              {{ $lang("section1.paragraph4") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <SectionHeader
      fileName="courses-spanish"
      id="spanish"
      class="img-top-center"
    >
      <h1 v-html="$lang('spanish_courses.header')" />
    </SectionHeader>

    <section class="py-7 bg-dark">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <h3 class="text-tertiary">
              {{ $lang("spanish_courses.information.header") }}
            </h3>
          </div>
          <div class="col-lg-6 offset-lg-2">
            <p>
              {{ $lang("spanish_courses.information.paragraph1") }}
            </p>

            <p>
              {{ $lang("spanish_courses.information.paragraph2") }}
            </p>

            <p>
              {{ $lang("spanish_courses.information.paragraph3") }}
            </p>
          </div>
        </div>

        <div class="form-row justify-content-center mt-5">
          <div
            class="col-sm-6 col-lg mb-3 mb-lg-0"
            v-for="(c, ci) of $lang('spanish_courses.buttons')"
            :key="ci"
          >
            <a
              :href="c.anchor"
              class="card card-tertiary card-button text-center justify-content-center"
            >
              <div class="card-body" style="margin-top: unset">
                <b>{{ c.title }}</b>
              </div>
            </a>
          </div>
        </div>

        <template
          v-for="(section, i) of $lang('spanish_courses.sections')"
          :key="i"
        >
          <div class="card card-outline-tertiary mt-4" :id="section.anchor">
            <div class="card-body text-center">
              <h3 class="m-0">{{ section.title }}</h3>
            </div>
          </div>

          <OurCoursesList :items="section.items" theme="tertiary" />
        </template>
      </div>
    </section>

    <SectionHeader fileName="courses-english" id="english">
      <h2 v-html="$lang('english_courses.header')" />
    </SectionHeader>

    <section class="py-7 bg-dark">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <h3 class="text-secondary">
              {{ $lang("english_courses.information.header") }}
            </h3>
          </div>
          <div class="col-lg-6 offset-lg-2">
            <p>
              {{ $lang("english_courses.information.paragraph1") }}
            </p>

            <p>
              {{ $lang("english_courses.information.paragraph2") }}
            </p>

            <p>
              {{ $lang("english_courses.information.paragraph3") }}
            </p>
          </div>
        </div>

        <div class="form-row justify-content-center mt-5">
          <div
            class="col-sm-6 col-lg mb-3 mb-lg-0"
            v-for="(c, ci) of $lang('english_courses.buttons')"
            :key="ci"
          >
            <a
              :href="c.anchor"
              class="card card-secondary card-button text-center justify-content-center"
            >
              <div class="card-body" style="margin-top: unset">
                <b>{{ c.title }}</b>
              </div>
            </a>
          </div>
        </div>

        <OurCoursesList
          :items="$lang('english_courses.items')"
          theme="secondary"
        />
      </div>
    </section>

    <SectionHeader fileName="courses-dele" id="dele_siele">
      <h2 v-html="$lang('dele_courses.header')" />
    </SectionHeader>

    <section class="py-7 bg-dark">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <h3 class="text-primary">
              {{ $lang("dele_courses.information.header") }}
            </h3>
          </div>
          <div class="col-lg-6 offset-lg-2">
            <p>
              {{ $lang("dele_courses.information.paragraph1") }}
            </p>

            <p>
              {{ $lang("dele_courses.information.paragraph2") }}
            </p>
          </div>
        </div>

        <div class="form-row justify-content-center mt-5">
          <div
            class="col-sm-6 col-lg mb-3 mb-lg-0"
            v-for="(c, ci) of $lang('dele_courses.buttons')"
            :key="ci"
          >
            <a
              :href="c.anchor"
              class="card card-primary card-button text-center justify-content-center"
            >
              <div class="card-body" style="margin-top: unset">
                <b>{{ c.title }}</b>
              </div>
            </a>
          </div>
        </div>

        <OurCoursesList :items="$lang('dele_courses.items')" theme="primary" />
      </div>
    </section>

    <section class="py-7 bg-tertiary">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <h4 class="mb-5 mb-lg-0 text-dark">
              {{ $lang("footer.title") }} <br />
              <span class="material-symbols-outlined arrow_right_alt">
                arrow_right_alt
              </span>
            </h4>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card1.title')"
              :to="{ name: 'meet_our_team' }"
            >
              <small class="m-0"> {{ $lang("footer.card1.body") }} </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card2.title')"
              :to="{ name: 'methodology', hash: '#levels' }"
            >
              <small class="m-0"> {{ $lang("footer.card2.body") }} </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card3.title')"
              :to="{ name: 'extra_activities' }"
            >
              <small class="m-0"> {{ $lang("footer.card3.body") }} </small>
            </CardButton>
          </div>
        </div>
      </div>
    </section>

    <ContactFormSection fileName="footer-7" theme="tertiary" id="form">
      {{ $lang("contact_form.location") }}
    </ContactFormSection>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";
import SectionHeader from "@/components/SectionHeader.vue";
import CardButton from "@/components/CardButton.vue";
import OurCoursesList from "@/components/OurCoursesList.vue";

export default {
  components: {
    PageHeader,
    ContactFormSection,
    SectionHeader,
    CardButton,
    OurCoursesList,
  },
};
</script>

<style></style>
