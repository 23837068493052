<template>
  <div class="row py-6" v-for="(c, ci) of items" :key="ci" :id="c.anchor">
    <div class="col-lg-5">
      <h4 class="mb-5" :class="`text-${theme}`">{{ c.title }}</h4>

      <div class="course-grid" v-if="c.table">
        <div class="grid-row">
          <p
            class="fw-600"
            :class="`text-${theme}`"
            v-html="c.table.schedule.title"
          />
          <p>{{ c.table.schedule.hours }}</p>
          <p>{{ c.table.schedule.days }}</p>
        </div>

        <template v-for="(s, si) of c.table.schedules" :key="si">
          <div class="grid-row pt-5" v-if="s.title">
            <p class="fw-600 mb-0 full-width" :class="`text-${theme}`">
              {{ s.title }}
            </p>
          </div>
          <div
            class="schedule-group"
            :style="`--theme: var(--color-${theme});`"
          >
            <div
              class="grid-row"
              :class="{
                'with-border': schedule.with_border,
                'mt-3': sci !== 0 && schedule.bold,
              }"
              v-for="(schedule, sci) of s.items"
              :key="sci"
            >
              <p :class="`text-${theme}`">
                <i>{{ schedule.title }}</i>
              </p>
              <p :class="{ 'font-weight-bold': schedule.bold }">
                {{ schedule.hours }}
              </p>
              <p>{{ schedule.days }}</p>
            </div>
          </div>
        </template>

        <div class="grid-row grid-no-title">
          <p>
            {{ c.table.depending }}
          </p>
        </div>
        <div class="grid-row grid-no-title" v-if="c.table.calendar">
          <a :href="c.table.calendar.link" :class="`text-${theme}`">
            <i class="fa fa-play mr-3"></i>
            {{ c.table.calendar.text }}
          </a>
        </div>

        <div class="grid-row grid-2-cols">
          <p class="fw-600" :class="`text-${theme}`">
            {{ c.table.levels.title }}
          </p>
          <p>{{ c.table.levels.desc }}</p>
        </div>

        <div class="grid-row grid-2-cols" v-if="c.table.prices">
          <p class="fw-600" :class="`text-${theme}`">
            {{ c.table.prices.title }}
          </p>
          <div>
            <p v-for="(p, pi) of c.table.prices.desc" :key="pi">
              {{ p }}
            </p>
          </div>
        </div>

        <div class="grid-row grid-no-title" v-if="c.table.prices">
          <p>{{ c.table.prices.msg }}</p>
        </div>

        <div class="grid-row grid-no-title">
          <router-link :to="{ name: 'prices' }" :class="`text-${theme}`">
            <i class="fa fa-play mr-3"></i>
            {{ c.check_prices }}
          </router-link>
        </div>

        <div class="grid-row grid-no-title">
          <a
            href="#form"
            class="btn btn-block text-center mb-6 mb-lg-0"
            :class="`btn-${theme}`"
          >
            {{ c.cta }}
          </a>
        </div>
      </div>

      <div class="course-grid mb-6" v-else>
        <div class="grid-row grid-no-title">
          <router-link :to="{ name: 'prices' }" :class="`text-${theme}`">
            <i class="fa fa-play mr-3"></i>
            {{ c.check_prices }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-lg-6 offset-lg-1">
      <ConnectedList
        :items="c.description"
        untilEnd
        class="h-100"
        :theme="theme"
        itemHeight="100%"
      />
      <router-link
        :to="{ name: 'student_visa' }"
        class="fw-300 ml-6 d-block"
        :class="`text-${theme}`"
        v-if="c.visa_requirements"
      >
        {{ c.visa_requirements }}
      </router-link>
    </div>
  </div>
</template>

<script>
import ConnectedList from "@/components/ConnectedList.vue";

export default {
  components: {
    ConnectedList,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: "primary",
    },
  },
};
</script>
